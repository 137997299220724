<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/account.css";
</style>
<style type="text/css" scoped>

</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";


  /**
   * PersonData--imgage
   */
  export default {
    components: {
      Layout,
      PageHeader,


    },
    data() {
      return {
        title: "部门设置",
        items: [{
            text: "用户首页",
            href: "/"
          },
          {
            text: "控制面板",
            active: true
          }
        ],


      };
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body ">
            <div class="pb-3 border-dash  check-table-top">
              <div class="flexList">
                <input placeholder="关键字" class="h30 form-control border-blue mr-2" maxlength="50"
                  style="width: 156px;" />
                <button type="button" class="btn btn-info h30 flexList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button v-b-modal.department variant="outline-info" class="flexList  condition ml-2"><i
                    class="iconfont icon-plus-square mr-2"></i>添加新部门</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%">序号</th>
                    <th style="width: 8%;">部门编码</th>
                    <th>部门名称</th>
                    <th style="width: 8%;">负责人账号</th>
                    <th style="width:6%;">负责人</th>
                    <th style="width: 50%;">部门职责 </th>
                    <th style="width:3%">编辑 </th>
                    <th style="width:3%">删除</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 1 </td>
                    <td> 1001 </td>
                    <td>人事处</td>
                    <td>3678</td>
                    <td class="text-info">王小川</td>
                    <td>
                      <div class="line3">
                        负责基地的信息化工作；负责各类机考的承接、技术支持、组织、实施工作和各类考试有关数据的处理；负责四川省人事考试基地和四川省人事考试基地网的建设、更新与维护；负责基地计算机设备的配置计划、维护与技术支持等工作。联系电话：028-83564040、
                        86514038（机考类）</div>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-riLine-file-edit-line mr-2"></i></td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line mr-2"></i>
                    </td>
                  </tr>
                  <tr>
                    <td> 1 </td>
                    <td> 1001 </td>
                    <td>人事处</td>
                    <td>3678</td>
                    <td class="text-info">王小川</td>
                    <td>
                      <div class="line3">
                        负责基地的信息化工作；负责各类机考的承接、技术支持、组织、实施工作和各类考试有关数据的处理；负责四川省人事考试基地和四川省人事考试基地网的建设、更新与维护；负责基地计算机设备的配置计划、维护与技术支持等工作。联系电话：028-83564040、
                        86514038（机考类）</div>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-riLine-file-edit-line mr-2"></i></td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line mr-2"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗添加部门 -->
    <b-modal id="department" centered size="m" title="添加新部门" title-class="font-18" hide-footer>
      <div>

        <div class="d-flex check-distri mb-3"><label>部门编码：</label>
          <div class=" col-sm-10 p-0 flexList">
            <input type="text" name="" id="" value="" placeholder="单行输入" class="form-control w-70 h30 " />

          </div>
        </div>
        <div class="d-flex check-distri mb-3"><label>部门名称：</label>
          <div class="col-sm-10  p-0">
            <input type="text" name="" id="" value="" placeholder="单行输入" class="form-control w-70 h30 " />



          </div>

        </div>
        <div class="d-flex check-distri mb-3"><label>负责人：</label>
          <div class="col-sm-10 pr-0 flexList"><input type="text" name="" id="" value="" placeholder="单行输入"
              class="form-control w-40 h30 " />
            <b-button variant="outline-info" class="flexList  condition ml-2 h30"><i
                class="iconfont icon-plus-circle mr-2" style="font-size: 24px;"></i>选择用户</b-button>
          </div>
        </div>
        <div class="d-flex check-distri mb-3"><label>部门职责：</label>
          <div class="col-sm-10 p-0"> <textarea rows="3" class="form-control w-100  line3"
              placeholder="部门职责"></textarea></div>
        </div>

      </div>

      <div class="text-center mt-3 check-modal"><button type="button" class="btn btn-info h30  mr-2">提交操作</button>
        <button type="button" class="btn btn-secondary h30  mr-2">取消操作</button>
      </div>
    </b-modal>
  </Layout>
</template>
